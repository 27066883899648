<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['运费模版']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">新增模版</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="template_name" label="名称"></el-table-column>
          <el-table-column prop="template_type" label="类型">
            <template slot-scope="scope">
              {{ scope.row.template_type == 0 ? "计件" : "计重" }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <el-tag type="success" effect="dark">否</el-tag>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length > 0 ? (page - 1) * length + 1 : "0"
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              @current-change="currentChange"
              :total="tableFinshNum"
              :current-page="page"
            ></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>
    <tem-add v-if="isShow == 1" @close="onClose" :data="data"></tem-add>
  </div>
</template>
<script>
import temAdd from "./childrens/temAdd.vue";

export default {
  components: {
    temAdd,
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
        isInquireValue: true,
      },

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercialId: "",
      data: "",
    };
  },
  mounted() {
    this.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTabList() {
      this.$http
        .post("/system/freight_template/lst", {
          currentLength: this.length,
          currentPage: this.page,
          commercial_id: this.commercialId,
        })
        .then((res) => {
          this.tableData = res.data?.data;
          this.tableFinshNum = res.data?.count;
        });
    },
    resetPasswords() {},
    handleEdit(row) {
      this.isShow = 1;
      this.data = row;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/system/freight_template/del", { id: row.id })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchClick() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const arr = [];
          this.multipleSelection.forEach((item) => {
            arr.push(item.id);
          });
          this.$http
            .post("/system/freight_template/bacthDel", { idArr: arr })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page += 1;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
    onClose() {
      this.isShow = 0;
      this.data = "";
      this.getTabList();
    },
    inquireBtnClick(data) {
      this.$http
        .post("/system/freight_template/search", {
          search: data.inquireValue,
          commercial_id: this.commercialId,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
