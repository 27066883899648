<template>
  <div>
    <bread-crumb
      :crumbData="['运费模板', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
      style="padding: 50px; width: 75%"
    >
      <el-form-item label="模板名称：" prop="temName">
        <el-input v-model="ruleForm.temName"></el-input>
      </el-form-item>
      <el-form-item label="模板类型：" prop="temType">
        <el-select v-model="ruleForm.temType">
          <el-option label="计件" :value="0"></el-option>
          <el-option label="计重" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="计费方式：" prop="chargeMode">
        <el-select v-model="ruleForm.chargeMode">
          <el-option label="统一运费" :value="0"></el-option>
          <el-option label="城市运费" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="套餐清单："
        required
        v-show="ruleForm.chargeMode == 1"
      >
        <el-table :data="ruleForm.cityDate" border style="width: 100%">
          <el-table-column label="城市：" :resizable="false">
            <template slot-scope="scope">
              <div>{{ scope.row.city }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="ruleForm.temType == 0 ? '首件数量：' : '首重(kg)'"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.first_number"
                style="width: 80%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :label="ruleForm.temType == 0 ? '首件价格：' : '首重价格'"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.first_price"
                style="width: 80%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :label="ruleForm.temType == 0 ? '续件数量：' : '续重(kg)'"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.continuations_number"
                style="width: 80%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :label="ruleForm.temType == 0 ? '续件价格：' : '续重价格'"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.continuations_price"
                style="width: 80%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" width="60">
            <template slot-scope="scope">
              <i
                class="el-icon-delete poiner"
                @click="cityDelete(scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="cityBilling = true"
          >添加城市</el-button
        >
      </el-form-item>
      <el-row v-if="ruleForm.chargeMode == 0">
        <el-col :span="10">
          <el-form-item label="统一运费：" prop="flatRate">
            <el-input v-model="ruleForm.flatRate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="收楼层费：" required>
        <el-switch
          v-model="ruleForm.floorCharge"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-row v-if="ruleForm.floorCharge">
        <el-col :span="10">
          <el-form-item label="起收楼层(>)：" prop="pickupFloor">
            <el-input v-model="ruleForm.pickupFloor"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="每层加价(每件)：" prop="raiseprice">
            <el-input v-model="ruleForm.raiseprice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="ruleForm.floorCharge && data"
        label="楼层区间："
        required
      >
        <el-table :data="ruleForm.sectionDate" border style="width: 100%">
          <el-table-column label="楼层" :resizable="false">
            <template slot-scope="scope">
              <el-input v-model="scope.row.floor" style="width: 80%" />
            </template>
          </el-table-column>
          <el-table-column label="加价（每件）" :resizable="false">
            <template slot-scope="scope">
              <el-input v-model="scope.row.floor_price" style="width: 80%" />
            </template>
          </el-table-column>
          <el-table-column label="加价（每件）" :resizable="false">
            <template slot-scope="scope">
              <i
                class="el-icon-delete poiner"
                @click="sectionDelete(scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addSectionClick">添加档位</el-button>
      </el-form-item>
      <el-form-item label="启用结算：" required>
        <el-switch
          v-model="ruleForm.enableSettlement"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="配送档位："
        v-if="ruleForm.enableSettlement"
        required
      >
        <el-table :data="ruleForm.tableData" border style="width: 100%">
          <el-table-column label="档位(件)" :resizable="false">
            <template slot-scope="scope">
              <el-input v-model="scope.row.gears" style="width: 85%"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="结算费用" :resizable="false">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.closing_cost"
                style="width: 85%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="送货工资" :resizable="false">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.delivery_wage"
                style="width: 85%"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column width="44px">
            <template slot-scope="scope">
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addgearClick">添加档位</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog title="城市选择" :visible.sync="cityBilling">
      <el-select
        v-model="ruleForm.province"
        placeholder="请选择省份"
        @focus="city1Focus"
        @change="city1Change"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in provinces"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="ruleForm.city"
        placeholder="请选择城市"
        @focus="city2Focus"
        @change="city2Change"
        v-if="ruleForm.province"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in cities"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="ruleForm.region"
        placeholder="请选择区"
        @focus="city3Focus"
        v-if="ruleForm.city"
      >
        <el-option
          v-for="item in regiones"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <div class="city-add">
        <el-button type="primary" @click="addCityBtn">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      ruleForm: {
        temName: "",
        temType: "",
        chargeMode: "",
        flatRate: "",
        floorCharge: false,
        pickupFloor: "",
        raiseprice: "",
        enableSettlement: false,
        tableData: [{ gears: "", closing_cost: "", delivery_wage: "0" }],
        province: "",
        city: "",
        region: "",
        cityDate: [
          {
            city: "全国",
            first_number: 1,
            first_price: "",
            continuations_number: 1,
            continuations_price: "",
          },
        ],
        sectionDate: [{ floor: 1, floor_price: 1 }],
      },
      rules: {
        temName: [{ required: true, message: "必填字段", trigger: "blur" }],
        flatRate: [{ required: true, message: "必填字段", trigger: "blur" }],
        pickupFloor: [{ required: true, message: "必填字段", trigger: "blur" }],
        raiseprice: [{ required: true, message: "必填字段", trigger: "blur" }],
        temType: [{ required: true, message: "必填字段", trigger: "change" }],
        chargeMode: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
      },
      cityBilling: false,
      provinces: [],
      cities: [],
      regiones: [],
      myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
      id: 0,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$props.data) {
      const data = this.$props.data;
      this.id = data.id;
      this.ruleForm.temName = data.template_name;
      this.ruleForm.temType = data.template_type;
      this.ruleForm.chargeMode = data.change_mode;
      this.ruleForm.floorCharge = data.floor_charge == 1 ? true : false;
      this.ruleForm.enableSettlement =
        data.enable_settlement == 1 ? true : false;
      this.ruleForm.flatRate = data.unified_billing;
      if (data.package_list) {
        this.ruleForm.cityDate = data.package_list;
      }
      this.ruleForm.pickupFloor = data.pick_up_floor;
      this.ruleForm.raiseprice = data.raise_price;
      if (data.floor_interval) {
        this.ruleForm.sectionDate = data.floor_interval;
      }
      if (data.deliver_gears) {
        this.ruleForm.tableData = data.deliver_gears;
      }
    }
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const subObj = {
            id: this.id,
            template_name: this.ruleForm.temName,
            template_type: this.ruleForm.temType,
            change_mode: this.ruleForm.chargeMode,
            floor_charge: this.ruleForm.floorCharge ? 1 : 0,
            enable_settlement: this.ruleForm.enableSettlement ? 1 : 0,
            commercial_id: this.commercial_id,
          };
          if (this.ruleForm.chargeMode == 0) {
            subObj.unified_billing = this.ruleForm.flatRate;
          }
          if (this.ruleForm.chargeMode == 1) {
            subObj.package_list = this.ruleForm.cityDate;
          }
          if (this.ruleForm.floorCharge) {
            subObj.pick_up_floor = this.ruleForm.pickupFloor;
            subObj.raise_price = this.ruleForm.raiseprice;
            if (this.$props.data) {
              subObj.floor_interval = this.ruleForm.sectionDate;
            }
          }
          if (this.ruleForm.enableSettlement) {
            subObj.deliver_gears = this.ruleForm.tableData;
          }
          this.$http
            .post("/system/freight_template/save", subObj)
            .then((res) => {
              if (res.data.status == "success") {
                this.$emit("close");
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cityDelete(index) {
      this.ruleForm.cityDate.splice(index, 1);
    },
    handleDelete(index) {
      this.ruleForm.tableData.splice(index, 1);
    },
    sectionDelete(index) {
      this.ruleForm.sectionDate.splice(index, 1);
    },
    addSectionClick() {
      this.ruleForm.sectionDate.push({ floor: "", floor_price: "" });
    },
    addCityBtn() {
      let city = "";
      if (this.ruleForm.region) {
        city = this.ruleForm.region;
      } else if (this.ruleForm.city) {
        city = this.ruleForm.city;
      } else if (this.ruleForm.province) {
        city = this.ruleForm.province;
      } else {
        return;
      }
      this.ruleForm.cityDate.push({
        city,
        first_number: 1,
        first_price: "",
        continuations_number: 1,
        continuations_price: "",
      });
      this.cityBilling = false;
      this.ruleForm.region = "";
      this.ruleForm.city = "";
      this.ruleForm.province = "";
    },
    addgearClick() {
      this.ruleForm.tableData.push({
        gears: "",
        closing_cost: "",
        delivery_wage: "0",
      });
    },
    city1Focus() {
      if (this.provinces.length == 0) {
        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
          key: this.myKey,
          callbackName: "QQmap",
          output: "jsonp",
        }).then((res) => {
          res.result[0].forEach((item) => {
            this.provinces.push({ name: item.fullname, id: item.id });
          });
        });
      }
    },
    city2Focus() {
      if (
        this.cities.length == 0 ||
        this.ruleForm.province != sessionStorage.getItem("province")
      ) {
        sessionStorage.setItem("province", this.ruleForm.province);
        this.cities = [];
        this.provinces.forEach((item) => {
          if (item.name == this.ruleForm.province) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.cities.push({
                  name: item.fullname,
                  id: item.id,
                });
              });
            });
          }
        });
      }
    },
    city3Focus() {
      if (
        this.regiones.length == 0 ||
        this.cities != sessionStorage.getItem("cities")
      ) {
        sessionStorage.setItem("cities", this.ruleForm.city);
        this.regiones = [];
        this.cities.forEach((item) => {
          if (item.name == this.ruleForm.city) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              console.log(res);
              res.result[0].forEach((item) => {
                this.regiones.push(item.fullname);
              });
            });
          }
        });
      }
    },
    city1Change() {
      this.ruleForm.city = "";
      this.ruleForm.region = "";
    },
    city2Change() {
      this.ruleForm.region = "";
    },
  },
};
</script>
<style lang="less" scoped>
.city-add {
  margin-top: 20px;
  text-align: center;
}
</style>
